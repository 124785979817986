// Generated by Framer (8184e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {s8phAWEVX: {hover: true}};

const cycleOrder = ["s8phAWEVX"];

const serializationHash = "framer-Qmz6K"

const variantClassNames = {s8phAWEVX: "framer-v-f4bz8x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({check, height, id, tap, width, ...props}) => { return {...props, BJr4mRxPf: tap ?? props.BJr4mRxPf, DbQMslb2P: check ?? props.DbQMslb2P ?? true} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;check?: boolean;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DbQMslb2P, BJr4mRxPf, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "s8phAWEVX", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapoa2cka = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (BJr4mRxPf) {
const res = await BJr4mRxPf(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-f4bz8x", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"s8phAWEVX"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapoa2cka} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-75959dab-2c20-4b1a-a94b-e2dfa46fe2e8, rgba(255, 255, 255, 0.1)) /* {\"name\":\"Border\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "linear-gradient(180deg, rgba(18, 19, 20, 0.5) 0%, rgba(5, 5, 5, 0.5) 100%)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({"s8phAWEVX-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}>{DbQMslb2P && (<motion.div className={"framer-1lv2wt-container"} layoutDependency={layoutDependency} layoutId={"vTfuGN1cK-container"}><Phosphor color={"var(--token-db045c8d-82bf-47c4-8c5a-9446a159a946, rgb(240, 240, 240)) /* {\"name\":\"Heading\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"Check"} id={"vTfuGN1cK"} layoutId={"vTfuGN1cK"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div>)}</motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-Qmz6K[data-border=\"true\"]::after, .framer-Qmz6K [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Qmz6K.framer-herytr, .framer-Qmz6K .framer-herytr { display: block; }", ".framer-Qmz6K.framer-f4bz8x { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: visible; padding: 12px 12px 12px 12px; position: relative; width: 32px; }", ".framer-Qmz6K .framer-1lv2wt-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Qmz6K.framer-f4bz8x { gap: 0px; } .framer-Qmz6K.framer-f4bz8x > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-Qmz6K.framer-f4bz8x > :first-child { margin-top: 0px; } .framer-Qmz6K.framer-f4bz8x > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LM6Ae3BX_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DbQMslb2P":"check","BJr4mRxPf":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerJH7D2769P: React.ComponentType<Props> = withCSS(Component, css, "framer-Qmz6K") as typeof Component;
export default FramerJH7D2769P;

FramerJH7D2769P.displayName = "Checkbox";

FramerJH7D2769P.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerJH7D2769P, {DbQMslb2P: {defaultValue: true, title: "Check", type: ControlType.Boolean}, BJr4mRxPf: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerJH7D2769P, [...PhosphorFonts])